import React from 'react';
import { HashRouter as Router, Switch, Route  } from "react-router-dom";
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Details from './pages/Details';
import Notfound from './pages/Notfound';
import ForgetPassword from './pages/ForgetPassword';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Profile from './pages/Profile';
import Contacts from './pages/Contacts';
import Interview from './pages/Interview';
import Live from './pages/Live';
import Schedule from './pages/Schedule';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/signin" component={Login} />
            <Route path="/signup" component={Register} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/details" component={Details} />
            <Route path="/404" component={Notfound} />
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/about" component={About} />
            <Route path="/profile" component={Profile} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/interview" component={Interview} />
            <Route path="/live" component={Live} />
            <Route path="/schedule" component={Schedule} />
          </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;