import React from 'react';

const Live = () => {
    return (
        <div>
        {/* head */}
        <section className="section section--head section--head-fixed">
            <div className="container">
            <div className="row">
                <div className="col-12 col-xl-6">
                <h1 className="section__title section__title--head">Live Matches</h1>
                </div>
                <div className="col-12 col-xl-6">
                <ul className="breadcrumb">
                    <li className="breadcrumb__item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb__item breadcrumb__item--active">Live TV</li>
                </ul>
                </div>
            </div>
            </div>
        </section>
        {/* end head */}
        {/* live */}
        <section className="section section--pb0">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <div className="section__carousel-wrap">
                    <div className="section__live owl-carousel" id="livetv">
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/1.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">6.5K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">RERUN: FURIA vs. Natus Vincere [Nuke] Map 2</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/2.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">1.7K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Wings Up vs Lynn Vision | (0-0) BO3</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/3.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">924 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">RERUN: Seither vs. RazerBlader - DH Masters: Winter 2020 - Playoffs - OCE</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/4.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">638 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Best of 2020: Top Trending Moments</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/5.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/6.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    </div>
                    <button className="section__nav section__nav--live section__nav--prev" data-nav="#livetv" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 7.72559L16.25 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M7.2998 1.70124L1.2498 7.72524L7.2998 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                    <button className="section__nav section__nav--live section__nav--next" data-nav="#livetv" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.72559L0.75 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M9.7002 1.70124L15.7502 7.72524L9.7002 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/* end live */}
        {/* categories */}
        <section className="section section--pb0">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <h2 className="section__title">Sports</h2>
                <p className="section__text">Live tournaments, match highlights, and your favorite pro players all in one place</p>
                </div>
            </div>
            <div className="row row--grid">
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/1.png" alt=""/>
                    </div>
                    <h3 className="category__title">Football</h3>
                    <span className="category__value">322</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/2.png" alt=""/>
                    </div>
                    <h3 className="category__title">Basket</h3>
                    <span className="category__value">174</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/3.png" alt=""/>
                    </div>
                    <h3 className="category__title">Fighting</h3>
                    <span className="category__value">509</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/4.png" alt=""/>
                    </div>
                    <h3 className="category__title">Moto Sport</h3>
                    <span className="category__value">1 228</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/5.png" alt=""/>
                    </div>
                    <h3 className="category__title">Volley</h3>
                    <span className="category__value">1 025</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/6.png" alt=""/>
                    </div>
                    <h3 className="category__title">King Boxing</h3>
                    <span className="category__value">819</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/7.png" alt=""/>
                    </div>
                    <h3 className="category__title">Roller Sport</h3>
                    <span className="category__value">3 601</span>
                </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <a href="category.html" className="category">
                    <div className="category__cover">
                    <img src="assets/img/category/8.png" alt=""/>
                    </div>
                    <h3 className="category__title">Biliardo</h3>
                    <span className="category__value">745</span>
                </a>
                </div>
            </div>
            </div>
        </section>
        {/* endcategories */}
        {/* recommended */}
        <section className="section section--pb0">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <h2 className="section__title">Recommended smaller communities</h2>
                </div>
                <div className="col-12">
                <div className="section__carousel-wrap">
                    <div className="section__live owl-carousel" id="recommended">
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/1.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">6.5K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">What Was Ben Affleck Planning for His Unmade 'Batman' Film?</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/2.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">1.7K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">A Guide to the Work of Ryan Murphy</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/6.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/3.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">924 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Gugu Mbatha-Raw Shares the Films That Give Her Hope</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/4.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">638 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Best of 2020: Top Trending Moments</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/5.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    </div>
                    <button className="section__nav section__nav--live section__nav--prev" data-nav="#recommended" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 7.72559L16.25 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M7.2998 1.70124L1.2498 7.72524L7.2998 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                    <button className="section__nav section__nav--live section__nav--next" data-nav="#recommended" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.72559L0.75 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M9.7002 1.70124L15.7502 7.72524L9.7002 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/* end recommended */}
        {/* hype */}
        <section className="section section--pb0 section--gradient">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <h2 className="section__title">All aboard the Hype Train!</h2>
                </div>
                <div className="col-12">
                <div className="section__carousel-wrap">
                    <div className="section__live owl-carousel" id="hype">
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/4.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">638 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Best of 2020: Top Trending Moments</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/5.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/3.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">924 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">Gugu Mbatha-Raw Shares the Films That Give Her Hope</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/1.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">6.5K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">What Was Ben Affleck Planning for His Unmade 'Batman' Film?</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/2.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">1.7K viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">A Guide to the Work of Ryan Murphy</a></h3>
                    </div>
                    <div className="live">
                        <a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="live__cover open-video">
                        <img src="assets/img/live/6.png" alt=""/>
                        <span className="live__status">live</span>
                        <span className="live__value">588 viewers</span>
                        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" strokeLinecap="round" strokeLinejoin="round" /><path fillRule="evenodd" clipRule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" strokeLinecap="round" strokeLinejoin="round" /></svg>
                        </a>
                        <div className="live__avatar">
                        <img src="assets/img/avatar.svg" alt=""/>
                        </div>
                        <h3 className="live__title"><a href="http://www.youtube.com/watch?v=ZujA_tXU5ts" className="open-video">How Movies and TV Shaped Our Perception of HIV/AIDS</a></h3>
                    </div>
                    </div>
                    <button className="section__nav section__nav--live section__nav--prev" data-nav="#hype" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 7.72559L16.25 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M7.2998 1.70124L1.2498 7.72524L7.2998 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                    <button className="section__nav section__nav--live section__nav--next" data-nav="#hype" type="button"><svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.72559L0.75 7.72559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M9.7002 1.70124L15.7502 7.72524L9.7002 13.7502" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/* end hype */}
        {/* partners */}
        <div className="section">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <div className="partners owl-carousel">
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/3docean-light-background.png" alt=""/>
                    </a>
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/activeden-light-background.png" alt=""/>
                    </a>
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/audiojungle-light-background.png" alt=""/>
                    </a>
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/codecanyon-light-background.png" alt=""/>
                    </a>
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/photodune-light-background.png" alt=""/>
                    </a>
                    <a href="/" className="partners__img">
                    <img src="assets/img/partners/themeforest-light-background.png" alt=""/>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        {/* end partners */}
        </div>

    );
};

export default Live;