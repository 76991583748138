import React from 'react';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
    return (
            <div>
            {/* sign in */}
            <div className="sign section--full-bg" style={{background: 'url("assets/img/bg.jpg") center center / cover no-repeat'}}>
                <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="sign__content">
                        {/* authorization form */}
                        <form action="#" className="sign__form">
                        <a href="/" className="sign__logo">
                            <img src="assets/img/logo.jpeg" alt=""/>
                        </a>
                        <div className="sign__group">
                            <input type="text" className="sign__input" placeholder="Email" />
                        </div>
                        <div className="sign__group sign__group--checkbox">
                            <input id="remember" name="remember" type="checkbox" defaultChecked="checked" />
                            <label htmlFor="remember">I agree to the <Link to="/privacy">Privacy Policy</Link></label>
                        </div>
                        <button className="sign__btn" type="button">Send</button>
                        <span className="sign__text">We will send a password to your Email</span>
                        </form>
                        {/* end authorization form */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* end sign in */}
            </div>

    );
};

export default ForgetPassword;